
import Footer from '../components/Footer.vue'
import Home from '../pages/index.vue'
import Notifications from '../components/Notifications.vue'

import ManageOrganization from '../components/ManageOrganization.vue'
import MarketPlaceLogo from '../components/logos/MarketPlace.vue'

const SMALL_LAYOUT_WIDHT_LIMIT = 1024

export default {
  name: 'Layout',
  components: {
    Footer,
    Home,
    Notifications,
    ManageOrganization,
    MarketPlaceLogo
  },
  middleware: ['auth'],
  scrollToTop: true,
  data () {
    return {
      smallLayout: false,
      showSidePanel: false,
      showLanguageSelector: false
    }
  },
  head () {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
  computed: {
    isConnected () {
      return this.$auth.loggedIn
    },
    isConnectedAndSmallLayout () {
      if (this.isConnected && this.smallLayout) {
        return false
      }
      return true
    },
    isModalDisplayed () {
      return this.$store.state.isModalDisplayed
    },
    publicApplications () {
      return this.$store.state.publicApplications
    },
    allApplications () {
      const publicApplications = this.publicApplications
      const privateApplications = this.organizationApplications
      return publicApplications.concat(privateApplications)
    },
    publicAndPrivateApplications () {
      const obj = this.allApplications.map(
        ({ name: title, long_description: text, logo, ...rest }) => ({
          title,
          text,
          logo,
          ...rest
        })
      )
      return obj
    },
    organizationApplications () {
      return this.$store.state.organizationApplications
    },
    search () {
      return this.$store.state.filter.search
    },
    results () {
      return this.$store.state.filter.results
    }
  },
  created () {
    if (process.client) {
      // eslint-disable-next-line
      window.vm = this.$root;
    }
  },
  mounted () {
    this.resizeObverser = new ResizeObserver(this.onResize)
    this.resizeObverser.observe(this.$el)
    this.$notifications.setComponentGetter(() => this.$refs.notifications)
  },
  destroyed () {
    if (this.resizeObverser) {
      this.resizeObverser.disconnect()
    }
  },
  methods: {
    openCreateAccount () {
      window.open(`${this.$config.connectUrl}/signup/`)
    },
    onSignInClick () {
      this.$auth.loginWith('oidc')
    },
    goToApplicationPage (application) {
      this.$router.push(
        this.localePath({
          path: `/application/${application.id}`
        })
      )
    },
    goToApplicationPageWithEnter (applicationId) {
      this.$router.push(
        this.localePath({
          path: `/application/${applicationId}`
        })
      )
    },
    goToAllResultsPage (event) {
      this.$store.dispatch('filterResults', event.results)
      this.$router.push(
        this.localeRoute({
          name: 'search',
          params: {
            results: event.results
          },
          query: {
            slug: event.search
          }
        })
      )
    },
    handleSearch (e) {
      this.$store.dispatch('filterSearch', e)
    },
    onResize (entries) {
      const lastEntry = entries[entries.length - 1]
      if (lastEntry.borderBoxSize.length > 0) {
        this.smallLayout = lastEntry.borderBoxSize[0].inlineSize < SMALL_LAYOUT_WIDHT_LIMIT
      } else {
        // Firefox does not seems to follow the specification : https://drafts.csswg.org/resize-observer/#resize-observer-entry-interface
        this.smallLayout = lastEntry.borderBoxSize.inlineSize < SMALL_LAYOUT_WIDHT_LIMIT
      }
    }
  }
}
