export default {
  back: 'Back',
  /***************************
   * COMPONENTS
   ***************************/
  /* footer component */
  footer: {
    about: 'About',
    cgu: 'Terms and conditions',
    copyright: 'Copyright ©2021 BIMData, Inc. All rigts reserved.',
    demo: 'Request a demo',
    dev: 'Developers',
    doc: 'Documentation',
    ds: 'Design system',
    language: 'Language',
    legalNotice: 'Legal Notice',
    other: 'Other',
    plans: 'Plans & Pricing',
    sales: 'Sales',
    salesContact: 'Sales contact',
    support: 'Support'
  },
  /* header component */
  header: {
    accountSettings: 'Account settings',
    chooseLanguage: 'Choose a language',
    createAccount: 'Create account',
    createOrganization: 'Create a billing account',
    devCorner: 'Dev corner',
    documentation: 'Documentation',
    logout: 'Log Out',
    mobileAccountTitle: 'Account',
    mobileSettingsTitle: 'Parameters',
    myOrganizations: 'My billing accounts',
    platform: 'Platform',
    settings: 'Settings',
    signIn: 'Sign in',
    website: 'BIMData.io'
  },
  /* ApplicationLogo component */
  applicationLogo: {
    welcome: 'BIMData Marketplace'
  },
  /* SelectSpace component */
  selectSpace: {
    fetchingSpaces: 'Fetching spaces',
    selectSpace: 'Select space'
  },

  /***************************
   * PAGES
   ***************************/
  application: {
    /* APPLICATION - activated-application page */
    activatedApplication: {
      accessSpace: 'request access to your space',
      allowBtn: 'Allow',
      denyBtn: 'Deny',
      installedApplications: 'installed applications page',
      privacyPolicy: 'Privacy Policy',
      reverseAuthorization:
        'At any moment, you can deny access to {name} or any other application via your',
      shareSensitiveInformation:
        'You are likely to share sensitive information through this website or application. By clicking “Allow”, you authorize this application to use your information in accordance with its',
      viewAccountInfo: 'View account info',
      viewAndManageGroups: 'View and manage groups',
      viewGroups: 'View groups',
      viewReportData: 'View report data'
    },
    /* APPLICATION - index page */
    index: {
      activate: 'Activate',
      download: 'Download',
      activateLogout: 'Sign in to activate',
      modifyBtn: 'Modify the application'
    },
    /* APPLICATION - success page */
    success: {
      applications: 'Applications',
      deactivateApplicationText:
        'You can deactivate this application at any time by accessing the page',
      goHome: 'Home page',
      inOrganizationText: 'in your billing account.',
      successActivated: 'Application successfully activated'
    }
  },
  devCorner: {
    /* DEV-CORNER - access-api page */
    apiAccess: {
      accessTypeConfidentialText:
        'confidential: For app with backend. Supports authorization code flow and client credential flow.',
      accessTypePublicText:
        'public: For mobile apps or SPA. Supports authorization code flow with PKCE and implicit flow (deprecated).',
      accessTypeTitle: 'Access Type',
      addNewRedirectUriBtn: 'Add a new one',
      apiAccessTitle: 'API access',
      baseUrlErrorMessage: '',
      baseUrlPlaceholder: 'Base URL',
      baseUrlTitle: 'Base URL',
      baseUrlText:
        'Default URL to use when the auth server needs to redirect or link back to your app.',
      createApplicationBtn: 'Create application',
      credentialsText:
        'Choose the name of your application. The name is displayed to users when requesting permissions and in their application list.',
      credentialsTitle: 'App credentials*',
      deleteRedirectUriBtn: 'Delete',
      helpText: 'Help?',
      linksInputTitlePlaceholder: 'Link title',
      linksInputTitleError: 'This field is required',
      linksInputUrlPlaceholder: 'Link url',
      linksInputUrlError: 'This field must have a valid format (eg: https://example.com)',
      nameErrorMessage: 'This field is required',
      namePlaceholder: 'Name',
      redirectUriPlaceholder: 'Redirect uri',
      notificationNoNameText: 'Please enter an application name.',
      notificationNoRedirectUriText: 'Please enter a redirect url.',
      redirectUrisErrorMessage: 'This field is required',
      redirectUrisText1:
        '- After allowing your app to access their data, users will be redirected to your app on one of these URIs.',
      redirectUrisText2: "- If you don't plan to authenticate users, the redirect_uri has little impact. You can put any URL whose domain you know.",
      redirectUrisTitle: 'Redirect uris*',
      removeRedirectUriBtn: 'Remove',
      scopesLimitationText: 'The limitations are:',
      scopesManageLimitationText:
        '- Manage: link the elements, create/delete the links between elements',

      scopesReadLimitationText: '- Read: access to the data in read-only mode',

      scopesText:
        'Your application’s user sees the scopes you registered as granted for your application and gives consent to the usage of their data based on this information. Set only the scopes you need.',
      scopesTitle: 'Scopes*',
      scopesWriteLimitationText: '- Write: edit the data'
    },
    /* DEV-CORNER - index page */
    index: {
      apiAccessCategoryBtn: 'Interact with the BIMData API',
      apiAccessCategoryText: 'Create your BIMData API access and start using the API now.',
      apiAccessCategoryTitle: 'API access',
      apiReferenceCategoryBtn: 'Open the API Reference',
      apiReferenceCategoryText:
        'Experiment with the BIMData API, and find the details of each endpoint.',
      apiReferenceCategoryTitle: 'API Reference',
      blogCategoryBtn: 'Read the latest posts',
      blogCategoryText: 'Want to read what BIMData and our Partners are working on ?',
      blogCategoryTitle: 'Blog',
      categoriesTitle: 'Interact with BIMData API or publish an app',
      developperCommunityCategoryBtn: 'Join us on Github',
      developperCommunityCategoryText:
        'A problem with BIMData technologies? Do not hesitate to come and discuss it with us.',
      developperCommunityCategoryTitle: 'Community',
      documentationCategoryBtn: 'Go to the documentation',
      documentationCategoryText: 'Learn about API core concepts, Viewer, and much more.',
      documentationCategoryTitle: 'Documentation',
      marketplaceCategoryBtn: 'Publish an application',
      marketplaceCategoryText: 'Create your new plugin on the BIMData Marketplace.',
      marketplaceCategoryTitle: 'Marketplace app',
      welcomeBtn: 'Explore',
      welcomeText: 'Learn about building, deploying, and managing your apps on BIMData.',
      welcomeTitle: 'Welcome to the Dev Corner',
      yourApiAccessCategoryBtn: 'Manage your API access',
      yourApiAccessCategoryText: 'Modify and / or revoke your API access.',
      yourApiAccessCategoryTitle: 'Your API access'
    },
    /* DEV-CORNER - marketplace-application page */
    marketplaceApplication: {},
    /* DEV-CORNER - your-acess-api page */
    yourAccessApi: {
      applicationActionsTitle: 'Actions',
      applicationNameTitle: 'Application name',
      modalApplicationCancelBtn: 'Cancel',
      modalApplicationDeleteBtn: 'Delete',
      modalApplicationListText: 'Are you sure you want to delete "{applicationName}" ?',
      modalApplicationListTitle: 'Delete {applicationName}',
      myApplicationsBtn: 'Create an API application',
      myApplicationsTitle: 'My applications',
      noApiApplicationsText: 'No API Applications...',
      id: {
        accessTypeText: 'Access Type :',
        addNewRedirectUriBtn: 'Add a new one',
        apiKeyText: 'API Key :',
        applicationCredentialsTitle: 'Application credentials',
        applicationInformationTitle: 'Application information',
        authentificationTitle: 'Authentification',
        clientIdText: 'Client ID :',
        clientSecretText: 'Client SECRET :',
        confidentialText: 'confidential',
        creatorNameText: 'Creator name :',
        fieldsDescription: 'Fields description',
        implicitFlowText: 'Implicit flow :',
        modalClientApiCancelBtn: 'Cancel',
        modalClientApiRegenerateBtn: 'Regenerate',
        modalClientApiConfirmText: 'Do you want to regenerate a new api key ?',
        modalClientApiText:
          'The current secret will be deleted and all applications using this secret must be updated',
        modalClientApiTitle: 'Regenerate api key',
        modalClientSecretCancelBtn: 'Cancel',
        modalClientSecretRegenerateBtn: 'Regenerate',
        modalClientSecretConfirmText: 'Do you want to regenerate a new client secret ?',
        modalClientSecretText:
          'The current secret will be deleted and all applications using this secret must be updated',
        modalClientSecretTitle: 'Regenerate client secret',
        organizationNameText: 'Billing account name :',
        publicText: 'public',
        redirectUrisText: 'Redirect URIs :',
        regenerateBtn: 'regenerate',
        removeRedirectUriBtn: 'Remove',
        scopesText: 'Scopes :',
        updateBtn: 'Update'
      }
    }
  },
  myOrganizations: {
    /* MY-ORGANIZATIONS - organization folder */
    organization: {
      /* MY-ORGANIZATIONS - organization - application folder */
      application: {
        /* MY-ORGANIZATIONS - organization - application folder - deactivate-application page */
        deactivateApplication: {
          cancelBtn: 'To cancel',
          deactivateBtn: 'Deactivate',
          fetchSpace: 'Fetching spaces',
          // notifications
          notificationMessageUpdateError:
            'The application has not been deactivated from the space {spaceName}',
          notificationTitleUpdateError: 'Deactivation error',
          notificationMessageUpdateSuccess:
            'The application has been deactivated from space: {spaceName}',
          notificationTitleUpdateSuccess: 'The application has been deactivated from space',
          selectSpace: 'Select space',
          textSelectSpace:
            'Please select the space in which you want to deactivate the application.'
        },
        /* MY-ORGANIZATIONS - organization - application folder - update-application page */
        updateApplication: {
          addLinkBtn: 'Add a new link',
          applicationLabel: 'New application name',
          deleteLinkBtn: 'Delete the link',
          errorMessage: 'This field is required',
          longDescriptionLabel: 'New long description',
          // notifications
          notificationMessageUpdateError: 'Your application could not be changed',
          notificationTitleUpdateError: 'Modification error',
          notificationMessageUpdateSuccess:
            'Your application has been modified and is now called {applicationName}',
          notificationTitleUpdateSuccess: 'Modified application',
          notificationsUrlTitleError: 'URL is not valid',
          notificationsUrlMessageError: 'Please enter a valid URL',
          sectionLogoTitle: 'Application logo',
          sectionLogoSubtitle: 'Update your application logo',
          sectionBasicInformationsTitle: 'Basic information',
          sectionBasicInformationsSubtitle: 'Update your application basic informations',
          sectionLinksTitle: 'Application links',
          sectionLinksSubtitle: 'Update your application links',
          sectionTagsTitle: 'Application tags',
          sectionTagsSubtitle: 'Update your application tags',
          sectionImagesTitle: 'Application images',
          sectionImagesSubtitle: 'Update your application images',
          shortDescriptionLabel: 'New short description *',
          tagsLabel: 'Tags',
          updateBtn: 'Update application',
          uploadImagesConditions:
            'Up to 6 images can be displayed in your app’s detail page. They must be in JPG/GIF/JPEG/PNG format. Only 5 images can be added for a total of 6 visual elements.',
          uploadLogoConditions:
            'The logo must be in JP(E)G / PNG format. The file size cannot exceed 1MB. The minimum logo width and height is 160 pixels and cannot exceed 400 pixels.'
        }
      },
      /* MY-ORGANIZATIONS - organization - space folder */
      space: {
        /* MY-ORGANIZATIONS - organization - space folder - update-space page */
        updateSpace: {
          errorMessage: 'This field is required',
          // notification
          notificationMessageError: 'Changing your space {spaceName} did not work',
          notificationTitleError: 'Unmodified space',
          notificationMessageSuccess: 'Your space is now called {spaceName}',
          notificationTitleSuccess: 'Modified space',
          spaceLabel: 'New space name',
          updateBtn: 'Update space'
        }
      },
      /* MY-ORGANIZATIONS - organization - activated-application-list page */
      activatedApplicationList: {
        deactivateBtn: 'Deactivate'
        // permissions: 'Permissions',
      },
      /* MY-ORGANIZATIONS - organization - applications page */
      applications: {
        activatedApplications: 'Activated applications',
        btnCreateApplication: 'Create an application',
        btnMoreApps: 'More apps',
        createApplicationBtn: 'Create app',
        emptyActivatedApplications:
          "You don't have any apps activated in your organization yet.",
        emptyOrganizationApplications: "You haven't created any apps in your billing account.",
        organizationApplications: 'Billing account applications',
        titleName: 'Name',
        titleSpaces: 'Spaces',
        titleActions: 'Actions',
        titleDescription: 'Description'
      },
      /* MY-ORGANIZATIONS - organization - billing page */
      billing: {},
      /* MY-ORGANIZATIONS - organization - create-application page */
      createApplication: {
        addLinkBtn: 'Add a new link',
        deleteLinkBtn: 'Delete the link',
        title: 'Create an application',
        applicationNameLabel: 'Application name *',
        errorMessage: 'This field is required',
        longDescriptionLabel: 'Long description *',
        // notifications
        notificationsTitleError: 'Application not created',
        notificationsMessageError: 'An error occurred while creating the application.',
        notificationsApplicationNameTitleError:
          'Error with your application name',
        notificationsApplicationNameMessageError:
          'The application could not be created, please check your application name.',
        notificationsShortDescriptionMessageError:
          'The application could not be created, please check the short description.',
        notificationsLongDescriptionMessageError:
          'The application could not be created, please check the long description.',
        notificationsUrlTitleError: 'URL is not valid',
        notificationsUrlMessageError: 'Please enter a valid URL',
        notificationsMessageSuccess:
          'The application {nameApplication} has just been created.',
        notificationsTitleSuccess: 'Application created',
        sectionLogoTitle: 'Application logo',
        sectionLogoSubtitle: 'Provide your application logo',
        sectionBasicInformationsTitle: 'Basic information',
        sectionBasicInformationsSubtitle: 'Provide your application basic informations',
        sectionLinksTitle: 'Application links',
        sectionLinksSubtitle: 'Provide your application links',
        sectionTagsTitle: 'Application tags',
        sectionTagsSubtitle: 'Provide your application tags',
        sectionImagesTitle: 'Application images',
        sectionImagesSubtitle: 'Provide your application images',
        shortDescriptionLabel: 'Short description (130 char. max) *',
        tagsLabel: 'Tags',
        uploadFormatLogoText: 'Logo should be in JP(E)G/PNG format.',
        uploadImagesText:
          'Up to 6 images can be shown in your app’s detail page. Should be in JPG/GIF/JPEG/PNG format. Only 5 images can be added for a total of 6 visual assets',
        validateBtn: 'Create the application'
      },
      /* MY-ORGANIZATIONS - organization - create-invitation page */
      createInvitation: {
        emailLabel: 'Email *',
        errorMessage: 'This field is required',
        // notification
        notificationMessageSuccess: 'Your invitation has been sent to',
        notificationTitleSuccess: 'Invitation sent',
        notificationMessageError: 'Your invitation to {email} could not be sent',
        notificationTitleError: 'Utilisateur non supprimé',
        textInvit: 'Invite someone to your billing account',
        validateBtn: 'Validate'
      },
      createSpace: {
        errorMessage: 'This field is required',
        // notification
        notificationsTitleSuccess: 'Space created successfully',
        notificationsMessageSuccess: 'The space {spaceName} has been successfully created.',
        notificationsTitleError: 'Error creating space',
        notificationsMessageError: 'Your new space could not be created, please try again.',
        spaceNameLabel: 'Space name *',
        titlePage: 'Space creation',
        validateBtn: 'Validate'
      },
      /* MY-ORGANIZATIONS - organization - index page */
      index: {
        settingsTab: 'Settings',
        applicationsTab: 'Applications',
        usersTab: 'Users',
        spacesTab: 'Spaces',
        billingTab: 'Billing'
      },
      /* MY-ORGANIZATIONS - organization - organization-application-list page */
      organizationApplicationList: {
        updateBtn: 'Update',
        deleteBtn: 'Delete'
      },
      /* MY-ORGANIZATIONS - organization - settings page */
      settings: {
        deleteBtn: 'Delete billing account',
        dontDeletePersonnalOrgaText: 'You cannot delete a personal billing account',
        errorMessage: 'This field is required',
        // notification for delete
        notificationTitleDeleteSuccess: 'Billing account deleted',
        notificationMessageDeleteSuccess:
          'You have just deleted the billing account {organizationName}',
        notificationTitleDeleteError: 'Billing account not deleted',
        notificationMessageDeleteError:
          'An error occurred while deleting the billing account {organizationName}',
        // notification for update logo
        notificationMessageUpdateLogoSuccess: 'Your billing account logo has changed',
        notificationTitleUpdateLogoSuccess: 'Modified billing account',
        notificationMessageUpdateLogoError: 'Your billing account could not be changed',
        notificationTitleUpdateLogoError: 'Error while modifying the logo',
        // notification for update name
        notificationMessageUpdateNameSuccess:
          'Your billing account is now called {organizationName}',
        notificationTitleUpdateNameSuccess: 'Modified billing account',
        notificationMessageUpdateNameError: 'Your billing account could not be changed',
        notificationTitleUpdateNameError: 'Modification error',
        organizationLabel: 'New billing account name',
        updateBtn: 'Update',
        uploadLogoConditions:
          'Logo should be in JP(E)G/PNG format. The file size cannot exceed 1 MB. Logo minimum width and height is 160px and cannot exceed 400px.',
        uploadLogoTitle: 'Upload logo'
      },
      /* MY-ORGANIZATIONS - organization - spaces page */
      spaces: {
        emptySpace:
          'Oh no. There are no spaces to display here. Create your first spaces linked to this billing account on the BIMData platform and come back to see me after.',
        introductionText: 'To add, modify or delete spaces, go to your ',
        bimdataPlatform: 'BIMData Platform'
      },
      /* MY-ORGANIZATIONS - organization - users page */
      users: {
        invitBtn: 'Invit',
        noResponse: 'Guest - No response.',
        // notification for delete pending invitation
        notificationDeletePendingInvitationMessageSuccess:
          'The invitation to user {email} has been canceled',
        notificationDeletePendingInvitationTitleSuccess: 'Invitation canceled',
        notificationDeletePendingInvitationMessageError:
          'The cancellation of the invitation to {email} could not be completed. Try again',
        notificationDeletePendingInvitationTitleError: 'Invitation not canceled',
        // notification for remove user
        notificationRemoveUserMessageSuccess:
          'The user with the address {email} has been successfully deleted from your billing account"',
        notificationRemoveUserTitleSuccess: 'User deleted',
        notificationRemoveUserMessageError:
          'An error occurred while deleting the user with your billing account {email} address. Try again',
        notificationRemoveUserTitleError: 'User not deleted',
        // notification for send back
        notificationSendBackMessageSuccess:
          'Your invitation has been sent to the email address: {email}',
        notificationSendBackTitleSuccess: 'Invitation resent',
        sendBackBtn: 'Resend invitation'
      }
    },
    /* MY-ORGANIZATIONS  - create-organization page */
    createOrganization: {
      basicInformations: 'Basic informations',
      basicInformationsText:
        'A billing account allows you to manage multiple BIMData spaces. Unlimited membership with a variety of roles that grant different levels of access to the billing account and its data.',
      errorMessage: 'This field is required',
      organizationName: 'Billing account name *',
      text: 'Billing accounts allow owners to centrally manage policy and billing for multiple BIMData.io spaces.',
      title: 'Create a billing account',
      uploadFormatLogoText: 'Logo should be in JP(E)G/PNG format.',
      uploadLogoText: 'Upload a logo for your billing account',
      validateBtn: 'Validate'
    },
    /* MY-ORGANIZATIONS  - index page */
    index: {
      createOrganization: 'Create a billing account',
      title: 'My billing accounts',
      titleActivatedApplications: 'Activated applications',
      titleOrganizationInfo: 'Billing account info',
      titleName: 'Name',
      titleUsers: 'Users'
    },
    /* MY-ORGANIZATIONS  - my-organization-list page */
    myOrganizationList: {
      delete: 'Delete',
      deleteOrganization: 'Delete billing account',
      // notification
      notificationOrganizationDeletedMessageError:
        'An error occurred while deleting the billing account',
      notificationOrganizationDeletedTitleError: 'Billing account not deleted',
      notificationOrganizationDeletedMessageSuccess:
        'You have just deleted the billing account {organizationName}',
      notificationOrganizationDeletedTitleSuccess: 'Billing account deleted',
      rename: 'Rename'
    },
    /* MY-ORGANIZATIONS  - success page */
    success: {
      goHome: 'Home page',
      organization: 'billing account',
      pageOrganizationText:
        'You can now modify, add users in your organization by going to the page of your',
      successCreated: 'Billing account created successfully'
    }
  },
  /* INDEX */
  index: {
    organizationApplications: 'Billing account applications',
    publicApplications: 'Public applications'
  },
  /* INVITATION */
  invitation: {
    acceptAllBtn: 'Accept all',
    inOrganization: 'in the billing account',
    invitationAccepted: 'Invitation accepted',
    invitedByText: 'You have been invited by',
    invitatationDenied: 'Invitation denied',
    invitationTitle: 'My invitations',
    // notification for invitation
    notificationInvitationMessageError: 'You refused to join the billing account',
    notificationInvitationTitleError: 'Invitation refused',
    notificationInvitationMessageSuccess: 'You are now part of the billing account',
    notificationInvitationTitleSuccess: 'Invitation accepted',
    // notification for invitations
    notificationInvitationsMessageSuccess: 'You are now part of the billing accounts',
    notificationInvitationsTitleSuccess: 'Accepted invitations',
    pending: 'Pending',
    title: 'Invitations'
  },

  /** LANDING DROPBOX */
  dropbox: {
    landingTitle: 'BIMData + Dropbox : User Guide',
    landingIntroductionText:
      "BIMData has now integrated with Dropbox, allowing users to take advantage of Dropbox as a Common Data Environment while accessing BIM models, 2D plans, photos, and BCF (industry-standard for annotation) within the platform. This integration streamlines project management and makes it easy for customers to continue using Dropbox as their go-to depository. If you're in the construction or O&M industry, be sure to check out this powerful new integration.",
    gettingStarted: {
      title: 'Getting started',
      step01: 'Step 01',
      step01Title: 'Install BIMData.io viewer',
      step01Text:
        'to install BIMData.io Viewer into your dropbox click on the following link:',
      step02: 'Step 02',
      step02Title: 'Authorize the BIMData application',
      step02Text: 'Click “Allow”'
    },
    needHelp: {
      title: 'Need help ?',
      text: 'Are you having a problem installing the BIMData plugin, or do you have any questions about this plugin?',
      button: 'Contact Support'
    },
    dropboxCde: {
      title: 'Dropbox as a CDE',
      text01:
        'You can manage project files with all the dropbox functionalities as well as BIM functionalities.',
      text02:
        'BIM functionalities on 3d Models, DWG, DXF, PDF and photos can be accessed through the three dots options from the document. Then select BIMData (viewer) in the “open in” function.'
    },
    functionnalities3d: {
      title: 'All BIMData features',
      subtitle: '3D Viewer',
      text01: "BIMData's viewer can be accessed through the dropbox environment",
      text02:
        "BIMData's viewer opens in a new tab. The viewer can read IFC files of any size and allows multi-model display. The viewer is equipped with the essential features that allow you to make the most of your BIM data:",
      treeStructure: 'Tree structure',
      bcfComments: 'BCF comments',
      cuttingTools: 'Cutting tools',
      viewer2d: '2D Viewer',
      measurementTools: 'Measurement tools'
    },
    measurementTools3d: {
      title: 'Measurement tools',
      text01:
        'Functionalities such as activation or deactivation of the on mouse-over highlight effects, edges or spaces are available.',
      text02: 'The 3D viewer integrate measurement and cutting tools.'
    },
    windowManager: {
      title: 'Window manager',
      text01:
        'A window manager allows you to create new windows that are interactive with each other. New window can be create vertically or horizontally.'
    },
    navigation: {
      title: 'Navigation',
      text01:
        'When selecting an object in the model, you access properties as well as the file structure and tree structure.'
    },
    bcfComments: {
      title: 'BCF comments',
      text01:
        'This international format allows you to make detailed comments linked to an object.',
      text02: 'BCF comments can be imported and exported from or to a third party software.'
    },
    viewerAndDropboxFiles: {
      title: '3D Viewer + Dropbox files',
      text01: 'Link your Dropbox files to 3D Models to get a unified view of:',
      itemList01: '3D models',
      itemList02: 'Model information',
      itemList03: 'Documentation'
    },
    bim2dViewer: {
      title: 'BIM 2D Viewer',
      text01:
        'A 2D view of BIM models is created to simplify the navigation. The window manager proves to be a very effective tool for navigation. 3D views can synchronize with the 2D ones.',
      text02:
        'BIMData 2D generator is developed in-house and automatically loads the 2D drawing of your 3D model.',
      text03: 'The 2D viewer can be synchronized with PDFs.'
    },
    measurementTools2d: {
      title: 'Measurement tools',
      text01:
        'Measurement tools on 2D proves to be a great asset to measure surfaces, angles, lengths and paths..'
    },
    plan2d: {
      title: '2D Plan',
      text01:
        'Integrate 2D plan (pdf, jpeg..) to BIM 2D model from dropbox. Synchronisation is easily done with a set of tools that handles size, rotation and the opacity for a perfect synchronization.'
    },
    dwgDxfFiles: {
      title: 'DWG - DXF Files',
      text01:
        'DWG and DXF files can be opened from Dropbox. Layers, measurement tools and BCF are displayed within the viewer.'
    },
    buildingMaker01: {
      title: 'Building maker',
      text01:
        'Create buildings storey structure for PDF plans. Once the building is created, you can use BIM functionalities on PDFs.'
    },
    buildingMaker02: {
      title: 'Building maker',
      text01: 'Navigate through the storey structure with PDFs.'
    },
    bimPictureViewer: {
      title: 'BIM Picture Viewer',
      text01:
        'Pictures are a the main source of information for the construction and O&M phases. ',
      text02:
        'Annotations (BCF) applied to pictures can prove to be a very powerful and inexpensive tools.'
    },
    bimCollaborationFormat01: {
      title: 'BIM Collaboration Format',
      text01: 'BCF facilitates communications and improves processes ',
      text02:
        'by identifying and exchanging issues. BIMData has a unique way to apply annotations to many files types (IFC, DWG, DXF, PDF, Pictures..)'
    },
    bimCollaborationFormat02: {
      title: 'BIM Collaboration Format',
      text01:
        'Documents with annotations can be filtered through Dropbox. Two tags are automatically added to files:',
      itemList01: '#bcf if a comment has been made',
      itemList02: '#bcfopened if a comment hasn’t been closed'
    }
  },

  /** LANDING DROPBOX */
  dropbox: {
    landingTitle: 'BIMData + Dropbox : User Guide',
    landingIntroductionText: "BIMData has now integrated with Dropbox, allowing users to take advantage of Dropbox as a Common Data Environment while accessing BIM models, 2D plans, photos, and BCF (industry-standard for annotation) within the platform. This integration streamlines project management and makes it easy for customers to continue using Dropbox as their go-to depository. If you're in the construction or O&M industry, be sure to check out this powerful new integration.",
    gettingStarted: {
      title: 'Getting started',
      step01: 'Step 01',
      step01Title: 'Install BIMData.io viewer',
      step01Text: 'to install BIMData.io Viewer into your dropbox click on the following link:',
      step02: 'Step 02',
      step02Title: 'Authorize the BIMData application',
      step02Text: 'Click “Allow”'
    },
    needHelp: {
      title: 'Need help ?',
      text: 'Are you having a problem installing the BIMData plugin, or do you have any questions about this plugin?',
      button: 'Contact Support'
    },
    dropboxCde: {
      title: 'Dropbox as a CDE',
      text01: 'You can manage project files with all the dropbox functionalities as well as BIM functionalities.',
      text02: 'BIM functionalities on 3d Models, DWG, DXF, PDF and photos can be accessed through the three dots options from the document. Then select BIMData (viewer) in the “open in” function.'
    },
    functionnalities3d: {
      title: 'All BIMData features',
      subtitle: '3D Viewer',
      text01: "BIMData's viewer can be accessed through the dropbox environment",
      text02: "BIMData's viewer opens in a new tab. The viewer can read IFC files of any size and allows multi-model display. The viewer is equipped with the essential features that allow you to make the most of your BIM data:",
      treeStructure: 'Tree structure',
      bcfComments: 'BCF comments',
      cuttingTools: 'Cutting tools',
      viewer2d: '2D Viewer',
      measurementTools: 'Measurement tools'
    },
    measurementTools3d: {
      title: 'Measurement tools',
      text01: 'Functionalities such as activation or deactivation of the on mouse-over highlight effects, edges or spaces are available.',
      text02: 'The 3D viewer integrate measurement and cutting tools.'
    },
    windowManager: {
      title: 'Window manager',
      text01: 'A window manager allows you to create new windows that are interactive with each other. New window can be create vertically or horizontally.'
    },
    navigation: {
      title: 'Navigation',
      text01: 'When selecting an object in the model, you access properties as well as the file structure and tree structure.'
    },
    bcfComments: {
      title: 'BCF comments',
      text01: 'This international format allows you to make detailed comments linked to an object.',
      text02: 'BCF comments can be imported and exported from or to a third party software.'
    },
    viewerAndDropboxFiles: {
      title: '3D Viewer + Dropbox files',
      text01: 'Link your Dropbox files to 3D Models to get a unified view of:',
      itemList01: '3D models',
      itemList02: 'Model information',
      itemList03: 'Documentation'
    },
    bim2dViewer: {
      title: 'BIM 2D Viewer',
      text01: 'A 2D view of BIM models is created to simplify the navigation. The window manager proves to be a very effective tool for navigation. 3D views can synchronize with the 2D ones.',
      text02: 'BIMData 2D generator is developed in-house and automatically loads the 2D drawing of your 3D model.',
      text03: 'The 2D viewer can be synchronized with PDFs.'
    },
    measurementTools2d: {
      title: 'Measurement tools',
      text01: 'Measurement tools on 2D proves to be a great asset to measure surfaces, angles, lengths and paths..'
    },
    plan2d: {
      title: '2D Plan',
      text01: 'Integrate 2D plan (pdf, jpeg..) to BIM 2D model from dropbox. Synchronisation is easily done with a set of tools that handles size, rotation and the opacity for a perfect synchronization.'
    },
    dwgDxfFiles: {
      title: 'DWG - DXF Files',
      text01: 'DWG and DXF files can be opened from Dropbox. Layers, measurement tools and BCF are displayed within the viewer.'
    },
    buildingMaker01: {
      title: 'Building maker',
      text01: 'Create buildings storey structure for PDF plans. Once the building is created, you can use BIM functionalities on PDFs.'
    },
    buildingMaker02: {
      title: 'Building maker',
      text01: 'Navigate through the storey structure with PDFs.'
    },
    bimPictureViewer: {
      title: 'BIM Picture Viewer',
      text01: 'Pictures are a the main source of information for the construction and O&M phases. ',
      text02: 'Annotations (BCF) applied to pictures can prove to be a very powerful and inexpensive tools.'
    },
    bimCollaborationFormat01: {
      title: 'BIM Collaboration Format',
      text01: 'BCF facilitates communications and improves processes ',
      text02: 'by identifying and exchanging issues. BIMData has a unique way to apply annotations to many files types (IFC, DWG, DXF, PDF, Pictures..)'
    },
    bimCollaborationFormat02: {
      title: 'BIM Collaboration Format',
      text01: 'Documents with annotations can be filtered through Dropbox. Two tags are automatically added to files:',
      itemList01: '#bcf if a comment has been made',
      itemList02: '#bcfopened if a comment hasn’t been closed'
    }
  }
}
