import Vue from 'vue'

const notificationsPlugin = {
  install (Vue) {
    let notificationsComponentGetter = null
    Vue.prototype.$notifications = {
      pushNotification (notification) {
        const notificationsComponent = notificationsComponentGetter()
        if (!notificationsComponent) {
          return false
        }
        notificationsComponent.pushNotification(notification)
      },
      closeNotification () {
        const notificationsComponent = notificationsComponentGetter()
        if (!notificationsComponent) {
          return false
        }
      },
      setComponentGetter (componentGetter) {
        notificationsComponentGetter = componentGetter
      }
    }
  }
}

Vue.use(notificationsPlugin)
