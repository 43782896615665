
export default {
  props: {
    type: {
      type: String,
      default: 'info',
      validator: type => ['success', 'error', 'warning', 'info'].includes(type)
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 6000
    }
  },
  data () {
    return {
      notifications: [],
      notificationId: 0,
      timeoutIds: new WeakMap()
    }
  },
  methods: {
    pushNotification (notification) {
      notification.id = this.notificationId++
      const timeoutId = setTimeout(() => {
        this.notifications = this.notifications.filter(a => a !== notification)
      }, this.delay)
      this.timeoutIds.set(notification, timeoutId)
      this.notifications.push(notification)
    },
    closeNotification (notification) {
      clearTimeout(this.timeoutIds.get(notification))
      this.notifications = this.notifications.filter(a => a !== notification)
    }
  }
}
