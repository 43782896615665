import { render, staticRenderFns } from "./HeaderNavigationMobile.vue?vue&type=template&id=5d6448b5&scoped=true&"
import script from "./HeaderNavigationMobile.vue?vue&type=script&lang=js&"
export * from "./HeaderNavigationMobile.vue?vue&type=script&lang=js&"
import style0 from "./_HeaderNavigationMobile.scss?vue&type=style&index=0&id=5d6448b5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6448b5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ManageInvitations: require('/opt/components/ManageInvitations.vue').default,UserAvatar: require('/opt/components/user-avatar/UserAvatar.vue').default,ManageOrganization: require('/opt/components/ManageOrganization.vue').default,LanguageSelector: require('/opt/components/LanguageSelector.vue').default})
