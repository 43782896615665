
import InvitationIcon from './icons/Invitation.vue'
const consola = require('consola')

export default {
  components: {
    InvitationIcon
  },
  data () {
    return {
      invitations: []
    }
  },
  computed: {
    isPendingInvitations () {
      return this.invitations.filter(invitation => invitation.status === 'P').length
    }
  },
  async created () {
    await this.getInvitations()
  },
  methods: {
    async getInvitations () {
      try {
        this.invitations = await this.$axios.$get(`${this.$config.backendUrl}/organization/my-invites`)
      } catch (e) {
        consola.log(e)
      }
    }
  }
}
