const VUEX_NAMESPACE = 'refreshAuth'
const REFRESH_PERIOD = '3000' * 1000
const AUTH_STRATEGY = 'oidc'

const state = () => ({refreshInterval: null})

const mutations = {
  refreshInterval (state, val) {
    if (state.refreshInterval) {
      clearInterval(state.refreshInterval)
    }
    state.refreshInterval = val
  },
}

const actions = {
  resetRefreshInterval ({ dispatch, commit }) {
    dispatch('stopRefreshInterval')

    const refresher = () => {
      dispatch('attemptRefresh')
    }

    setTimeout(
      () => commit('refreshInterval', setInterval(refresher, REFRESH_PERIOD)),
      REFRESH_PERIOD / 2
    )
  },
  stopRefreshInterval ({commit}) {
    commit('refreshInterval', null)
  },
  async attemptRefresh ({dispatch, commit}) {
    try {
      await this.$auth.strategies[AUTH_STRATEGY].refreshTokens()
    } catch (e) {
      if (this.$authRefresh._onErrorhandler) {
        this.$authRefresh._onErrorhandler(e)
      } else {
        throw e
      }
    }
  }
}

const storeModule = {
  state, actions, mutations, namespaced: true
}

export default ({$axios, store}, inject) => {
  store.registerModule(VUEX_NAMESPACE, storeModule, {
    preserveState: Boolean(store.state[VUEX_NAMESPACE])
  })

  inject('authRefresh', {
    _onErrorhandler: null,
    initRefreshInterval () {
      store.dispatch(VUEX_NAMESPACE + '/resetRefreshInterval')
    },
    onRefreshError(handler) {
      this._onErrorhandler = handler
    }
  })
}
