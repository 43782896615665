
export default {
  middleware: ['auth'],
  data () {
    return {
      showLanguageSelector: false
    }
  },
  computed: {
    connectUrl () {
      return this.$config.connectUrl + '/profile/'
    },
    platformUrl () {
      return this.$config.platformUrl
    },
    userName () {
      if (this.$auth.user) {
        return `${this.$auth.user.given_name} ${this.$auth.user.family_name}`
      } else {
        return 'NN'
      }
    },
    email () {
      if (this.$auth.user) {
        return this.$auth.user.email
      } else {
        return 'NN'
      }
    },
    initials () {
      return this.$auth.user.given_name
        ? (this.$auth.user.given_name[0] + this.$auth.user.family_name[0]).toUpperCase()
        : 'NN'
    }
  },
  created () {
    if (process.client) {
      // eslint-disable-next-line
      window.vm = this.$root;
    }
  },
  methods: {
    onLogoutClick () {
      this.$auth.logout()
    },
    closeLanguageSelector () {
      this.showLanguageSelector = false
    }
  }
}
