import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=70d37179&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=js&"
export * from "./LanguageSelector.vue?vue&type=script&lang=js&"
import style0 from "./_LanguageSelector.scss?vue&type=style&index=0&id=70d37179&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d37179",
  null
  
)

export default component.exports