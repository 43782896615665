function sortName(a, b) {
  a = a.name.toLowerCase();
  b = b.name.toLowerCase();
  if (a > b) {
    return -1;
  } else if (b > a) {
    return 1;
  } else {
    return 0;
  }
}

export { sortName };
