import { sortName } from '../utils/sortName'
const consola = require('consola')

const storeModule = {
  mutations: {
    // mutation - add application in organization
    addOrganizationApplication (state, newApplication) {
      state.organizationApplications.push(newApplication)
    },
    // mutation - delete organization
    deleteOrganization (state, organization = {}) {
      const organizationIndex = state.organizations.findIndex(
        orga => orga.id === organization.id
      )
      if (organizationIndex !== -1) {
        state.organizations.splice(organizationIndex, 1)
      }
    },
    // mutation - set filter search
    setFilterSearch (state, search) {
      state.filter.search = search
    },
    // mutation - set searchbar
    setFilterResults (state, results) {
      consola.log({ results })
      state.filter.results = results
    },
    // mutation - set current organization
    setCurrentOrganization (state, currentOrganization) {
      state.currentOrganization = currentOrganization
    },
    setCurrentApplication (state, currentApplication) {
      state.currentApplication = currentApplication
    },
    setModalDisplayed (state, isModalDisplayed) {
      state.isModalDisplayed = isModalDisplayed
    },
    // mutation - set pending invitations
    setPendingInvitations (state, pendingInvitations) {
      state.pendingInvitations = pendingInvitations
    },
    // mutation - set public applications
    setPublicApplications (state, publicApplications) {
      const sortedPublicApplications = publicApplications.sort(sortName).reverse()
      state.publicApplications = sortedPublicApplications
    },
    // mutation - set applications in organization
    setOrganizationApplications (state, organizationApplications) {
      const sortedOrganizationApplications = organizationApplications.sort(sortName).reverse()
      state.organizationApplications = sortedOrganizationApplications
    },
    setApiApplications (state, apiApplications) {
      const sortedApiApplications = apiApplications.sort(sortName).reverse()
      state.apiApplications = sortedApiApplications
    },
    // mutation - set application images
    setApplicationImages (state, applicationImages = []) {
      state.applicationImages = applicationImages
    },
    // mutation - set applications
    setApplications (state, applications = []) {
      state.applications = applications
    },
    // mutation - set spaces
    setSpaces (state, spaces = []) {
      state.spaces = spaces
    },
    // mutation - set organizations
    setOrganizations (state, organizations = []) {
      state.organizations = organizations
    },
    // mutation - update api application
    updateApiApplication (state, apiApplication = {}) {
      const apiApplicationIndex = state.apiApplications.findIndex(
        apiApplication => apiApplication.id === apiApplication.id
      )
      if (apiApplicationIndex !== -1) {
        state.apiApplications.splice(apiApplicationIndex, 1, apiApplication)
      }
    },
    // mutation - update application
    updateOrganizationApplication (state, application = {}) {
      const applications = state.organizationApplications
      const index = applications.findIndex(app => app.id === application.id)
      if (index !== -1) {
        applications.splice(index, 1, application)
      } else {
        return applications
      }
    },
    // mutation - update application
    updatePublicApplication (state, application = {}) {
      const applications = state.publicApplications
      const index = applications.findIndex(app => app.id === application.id)
      if (index !== -1) {
        applications.splice(index, 1, application)
      } else {
        return applications
      }
    },
    // mutation - update organization
    updateOrganization (state, organization = {}) {
      const organizationIndex = state.organizations.findIndex(
        orga => orga.id === organization.id
      )
      if (organizationIndex !== -1) {
        state.organizations.splice(organizationIndex, 1, organization)
      }
    },
    // mutation - update space
    updateSpace (state, space = {}) {
      const spaceIndex = state.spaces.findIndex(s => s.id === space.id)
      if (spaceIndex !== -1) {
        state.space.splice(spaceIndex, 1, space)
      }
    }
  },
  actions: {
    // action - nuxt server init
    async nuxtServerInit ({ commit }) {
      let publicApplications = []
      try {
        const response = await fetch(`${this.$config.apiUrl}/private/marketplace-apps`)
        if (response.ok) {
          publicApplications = await response.json()
        } else {
          throw response
        }
      } catch (err) {
        consola.error('Cannot get public applications.', err)
      }
      commit('setPublicApplications', publicApplications)
    },
    // action - set current organization
    async setCurrentOrganization ({ commit, state }, currentOrganization) {
      try {
        const [organizationApplications, apiApplications] = await Promise.all([
          this.$axios.$get(`${this.$config.apiUrl}/private/organization/${currentOrganization.id}/marketplace-app`),
          this.$axios.$get(`${this.$config.apiUrl}/private/organization/${currentOrganization.id}/app`)
        ])
        commit('setOrganizationApplications', organizationApplications)
        commit('setApiApplications', apiApplications)
      } catch (err) {
        consola.error('Cannot get private applications.', err)
      }
      commit('setCurrentOrganization', currentOrganization)
    },
    // action - set user
    async setBaseData ({ commit, dispatch, state }) {
      if (this.$auth.$state.loggedIn) {
        let organizations = []
        try {
          organizations = await this.$axios.$get(`${this.$config.apiUrl}/private/organization`)
        } catch (e) {
          consola.error(`Unable to get organizations. ${e}`)
        }
        commit('setOrganizations', organizations)
        if (organizations.length > 0 && !state.currentOrganization) {
          await dispatch(
            'setCurrentOrganization',
            organizations.find(organization => organization.is_personnal)
          )
        }
        let applications = []
        try {
          applications = await this.$axios.$get(`${this.$config.apiUrl}/private/organization/${state.currentOrganization.id}/marketplace-app`)
        } catch (e) {
          consola.error(`Unable to get applications. ${e}`)
        }
        commit('setApplications', applications)
        let apiApplications = []
        try {
          apiApplications = await this.$axios.$get(`${this.$config.apiUrl}/private/organization/${state.currentOrganization.id}/app`)
        } catch (e) {
          consola.error(`Unable to get api applications. ${e}`)
        }
        commit('setApiApplications', apiApplications)
      } // TODO handle logout
    },
    // action  - filterSearch
    filterSearch ({ commit }, search) {
      commit('setFilterSearch', search)
    },
    // action  - filterResults
    filterResults ({ commit }, results) {
      commit('setFilterResults', results)
    }
  },
  getters: {
    // getter - get application
    getApplication (state) {
      return id =>
        state.organizationApplications.find(
          organizationApplication => organizationApplication.id === Number(id)
        ) ||
        state.publicApplications.find(
          publicApplication => publicApplication.id === Number(id)
        )
    },
    //
    getApiApplication (state) {
      return id =>
        state.apiApplications.find(apiApplication => apiApplication.id === Number(id))
    },
    // getter - get organization
    getOrganization (state) {
      return id =>
        state.organizations.find(organization => organization.id === Number(id))
    },
    getSpace (state) {
      return id => state.spaces.find(space => space.id === Number(id))
    },
    // getter - applications
    applications (state) {
      return state.organizationApplications.concat(state.publicApplications)
    }
  }
}

export const state = () => ({
  organizations: [],
  currentOrganization: null,
  publicApplications: [],
  apiApplications: [],
  organizationApplications: [],
  pendingInvitations: false,
  currentApplication: null,
  applicationImages: [],
  spaces: [],
  isModalDisplayed: false,
  filter: {
    search: '',
    results: []
  }
})

export const getters = storeModule.getters

export const actions = storeModule.actions

export const mutations = storeModule.mutations
