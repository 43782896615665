
import AppLogo from '../components/logos/App.vue'

export default {
  name: 'MainPage',
  components: {
    AppLogo
  },
  auth: false,

  transition: 'custom-transition',
  computed: {
    publicApplications () {
      return this.$store.state.publicApplications
    },
    organizationApplications () {
      return this.$store.state.organizationApplications
    }
  }
}
