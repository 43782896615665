
import OrganizationIcon from './icons/Organization.vue'

export default {
  components: {
    OrganizationIcon
  },
  props: {
    width: {
      type: String,
      default: '220px'
    }
  },
  computed: {
    currentOrganization () {
      return this.$store.state.currentOrganization
    },
    organizations () {
      return this.$store.state.organizations
    },
    isManageOrganizationDisabled () {
      const routePath = this.$route.path
      const organizationRegex = /\/organization\/\d+/
      const myOrganizationRegex = /\/my-organizations/
      const createApplicationRegex = /\/create-application/
      const createInvitationRegex = /\/create-invitation/
      return (
        organizationRegex.test(routePath) ||
        myOrganizationRegex.test(routePath) ||
        createApplicationRegex.test(routePath) ||
        createInvitationRegex.test(routePath)
      )
    }
  },
  methods: {
    onOrganizationClick (organization) {
      this.$store.dispatch('setCurrentOrganization', organization)
    }
  }
}
