
export default {
  computed: {
    initials () {
      return this.$auth.user.given_name
        ? (this.$auth.user.given_name[0] + this.$auth.user.family_name[0]).toUpperCase()
        : 'NN'
    }
  }
}
