
import BIMDataLogo from './logos/BIMData.vue'
import LinkedinLogo from './logos/Linkedin.vue'
import GithubLogo from './logos/Github.vue'
import TwitterLogo from './logos/Twitter.vue'
export default {
  components: { BIMDataLogo, LinkedinLogo, GithubLogo, TwitterLogo },
  data () {
    return {
      showLanguageSelector: false
    }
  },
  computed: {
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },
  methods: {
    closeLanguageSelector () {
      this.showLanguageSelector = false
    }
  }
}
